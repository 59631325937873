<script setup lang="ts">
import { userStore } from '~/stores/user';

const state = reactive({
  indexTabSelected: 0,
});

const user = computed(() => {
  return userStore().currentUser;
});
</script>

<template>
  <div>
    <ui-header :title="$t('profile.my_profile')" />

    <div class="p-4">
      <ui-card class="relative">
        <form v-if="state.indexTabSelected === 0" class="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
          <!-- Profile section -->
          <div class="py-6 px-4 sm:p-6 lg:pb-8">
            <div>
              <h2 class="text-lg leading-6 font-medium text-gray-900">{{}}</h2>
              <p class="mt-1 text-sm text-gray-500">--- ---</p>
            </div>

            <div class="mt-6 flex flex-col lg:flex-row">
              <div class="flex-grow space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-700">{{ $t('global.email') }}</label>
                  <div class="mt-1 rounded-md shadow-sm flex">
                    <input
                      id="username"
                      type="text"
                      name="username"
                      autocomplete="username"
                      class="focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 dark:text-black"
                      :value="user.email"
                      readonly
                    />
                  </div>
                </div>
              </div>

              <div class="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
                <p class="text-sm font-medium text-gray-700" aria-hidden="true">
                  {{ $t('global.photo') }}
                </p>
                <div class="mt-1 lg:hidden">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12" aria-hidden="true">
                      <img
                        class="rounded-full h-full w-full"
                        :src="user.picture_url"
                        onerror="javascript:this.src='/assets/anonymous-avatar-icon-25.webp'"
                        :alt="user.first_name"
                      />
                    </div>
                    <div class="ml-5 rounded-md shadow-sm">
                      <div
                        class="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500"
                      >
                        <label for="mobile-user-photo" class="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none">
                          <span>Change</span>
                          <span class="sr-only">user photo</span>
                        </label>
                        <input
                          id="mobile-user-photo"
                          name="user-photo"
                          type="file"
                          class="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md dark:text-black"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hidden relative rounded-full overflow-hidden lg:block">
                  <img
                    class="relative rounded-full w-40 h-40"
                    :src="user.picture_url"
                    onerror="javascript:this.src='/assets/anonymous-avatar-icon-25.webp'"
                    alt="photo"
                  />
                  <label
                    for="desktop-user-photo"
                    class="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
                  >
                    <span>Change</span>
                    <span class="sr-only">user photo</span>
                    <input
                      id="desktop-user-photo"
                      type="file"
                      name="user-photo"
                      class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div class="mt-6 grid grid-cols-12 gap-6">
              <div class="col-span-12 sm:col-span-6">
                <label for="first-name" class="block text-sm font-medium text-gray-700">{{ $t('global.first_name') }}</label>
                <input
                  id="first_name"
                  type="text"
                  name="first_name"
                  autocomplete="given-name"
                  :value="user.first_name"
                  readonly
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm dark:text-black"
                />
              </div>

              <div class="col-span-12 sm:col-span-6">
                <label for="last-name" class="block text-sm font-medium text-gray-700">{{ $t('global.last_name') }}</label>
                <input
                  id="last_name"
                  type="text"
                  name="last_name"
                  autocomplete="family-name"
                  :value="user.last_name"
                  readonly
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm dark:text-black"
                />
              </div>

              <div class="col-span-12 sm:col-span-6">
                <label for="company" class="block text-sm font-medium text-gray-700">{{ $t('global.client') }}</label>
                <input
                  id="client"
                  type="text"
                  name="client"
                  autocomplete="organization"
                  :value="user.client_name"
                  readonly
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm dark:text-black"
                />
              </div>
            </div>
            <div class="flex justify-end pt-[100px]" />
          </div>
        </form>
      </ui-card>
    </div>
  </div>
</template>
